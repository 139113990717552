import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomerReviewCard from "./CustomerReviewCard";
import styles from "./CustomerReview.module.css";

const CustomerReview = ({ isPrimaryColor }) => {
  const { t } = useTranslation();

  // Define primary and secondary colors
  const primaryColor = "#0e3747"; // Primary color
  const secondaryColor = "#1d4e5b"; // Secondary color

  // Determine background color based on isPrimaryColor prop
  const backgroundColor = isPrimaryColor ? primaryColor : secondaryColor;
  const reviewCardBackgroundColor = isPrimaryColor ? secondaryColor : primaryColor;

  return (
    <div style={{ backgroundColor }} className={styles.res100}> 
    <div style={{ backgroundColor }} className={styles.komponenta}>
      <h4 className={styles.naslov}>{t("Nasi_klijenti")}</h4>
      <h3 className={styles.podnaslov}>{t("Zasto_se_nasim_korisnicima_dopada_Concierge_of_Football")}</h3>
      <Row className={styles.komentari}>
        <Col xs={12} lg={4} className={styles.kolonakomentari}>
          <CustomerReviewCard
            image={"/images/derby.png"}
            text={t("Danas na jednostavan nacin, kroz svakodnevni uvid, znamo koje su radnje preduzete u klubu, a koje u nasoj ambulanti, gde je potpunosti iskljucujeno postojanje nezeljenih efekata.")}
            author={t("Zdravko Marinković")}
            position={t("MM PHYSICAL Beograd")}
            backgroundColor={reviewCardBackgroundColor}
          />
        </Col>
        <Col xs={12} lg={4} className={styles.kolonakomentari}>
          <CustomerReviewCard
            image={"/images/paypal.png"}
            text={t("CoF mi je pruzio mogucnost da u realnom vremenu svakog dana znam koliko cu dece imati na treningu radi plana i organizacije samog treninga.")}
            author={t("Milos Bajic")}
            position={t(" IMPERIAL 21")}
            backgroundColor={reviewCardBackgroundColor}
          />
        </Col>
        <Col xs={12} lg={4} className={styles.kolonakomentari}>
          <CustomerReviewCard
            image={"/images/shortcut-script-app.png"}
            text={t("Nekada imam i 4 treninga dnevno,samo oko 2h dnevno trošim na samu organizaciju i koordinaciju, danas sve završavam kroz 15 minuta uz vrlo jednostavan način upotrebe")}
            author={t("Bojan Miletic")}
            position={t("IMT Beograd")}
            backgroundColor={reviewCardBackgroundColor}
          />
        </Col>
      </Row>
    </div>
    </div>
  );
};

export default CustomerReview;
