import React from "react";
import { Col,Row,Button} from "react-bootstrap";
import styles from "./CustomerSupport.module.css"
import { useTranslation } from "react-i18next";
const CustomerSupport = ()=>{
  const { t } = useTranslation();
    return(

        <div className={styles.kontejner} >
          <Row className={styles.wraprow}>
            <Col  xs={12} lg={6} className={styles.prvidiv}>
            <h5 className={styles.prednaslov} > {t("Concierge of football podrska")}</h5>
            <h3 className={styles.naslov} >{t("Na usluzi u svakom trenutku.")}</h3>
            <p className={styles.podnaslov}>{t("Nas tim je udaljen samo na klik")} <br></br> {t("kako bi vam pomogao da vas tim napreduje.")}</p>
            <Row> 
                <Col><p className={styles.podnaslov}> <img src="/images/check.png" className={styles.ikonica} ></img>{t("Podrska email-om")}</p></Col>
                <Col><p className={styles.podnaslov}>  <img src="/images/check.png" className={styles.ikonica}></img>{t("Uzivo chat")}</p></Col>
            </Row>
            <Row>
                <Col><p className={styles.podnaslov}><img src="/images/check.png" className={styles.ikonica}></img>{t("Redovni seminari")}</p></Col>
                <Col><p className={styles.podnaslov}><img src="/images/check.png" className={styles.ikonica}></img>{t("Konsultacije")}</p></Col>
            </Row>
            </Col>
            <Col xs={12} lg={6} className={styles.drugidiv}>
              <div className={styles.mestoslike}>
                <img alt="nema sliak" src="/images/Capturecof.PNG"  className={styles.slika}></img>
              </div>
            </Col>
          </Row>
        </div>
    )
}

export default CustomerSupport;