import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from "../CustomButton";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../../Screens/Solutions.module.css"
const SolutionHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleDemoClick = () => {
    // Navigacija na novu stranicu kada se klikne na "Get a Demo"
    navigate('/demo');
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Dodajte 'smooth' za glatko pomeranje
    });
  };

const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;
const signUpUrl = `${appBaseUrl}/signup`;

const handleSignUpClick = () => {
  // Preusmeravanje na stranicu za registraciju
  window.location.href = signUpUrl;
};

  return (
    <div className={styles.solutionheader} style={{
      backgroundColor: "rgb(14,55,71)",
      color: 'white',
      
    }}>
      <Row>
        <Col md={1}></Col>
        <Col>
          <h3 className={styles.upoznaj}>{t("Upoznaj CoF koji ti pomaže da unaprediš organizaciju kluba")}</h3>
          <h1 className={styles.pojednostavi}>{t("Pojednostavite organizaciju klupskih resursa uz pomoć")}<span style={{color:"rgb(0, 198, 19)"}}>{t("Concierge Of Football")}</span>{t("platforme")}</h1>
          <p className={styles.unapredi}>{t("Unapredi način na koji organizuješ")}</p>
          
          <div className="d-flex justify-content-center align-items-center">
            <CustomButton label={t("Prijavite_se_besplatno")} className="custombuttonsize" onClick={handleSignUpClick}></CustomButton>
            <div className={styles.btnpad} style={{ margin: '10px 0', display: 'inline-block' }}>{t("or")}</div>
            <CustomButton label={t("Zakazite demo verziju")} className="custombuttonsize" onClick={handleDemoClick}></CustomButton>
          </div>
          <p className={styles.isprobajte}>{t("Isprobajte_sve_funkcije_7_dana")}</p>
        </Col>
        <Col md={1}></Col>
      </Row>
    </div>
  );
};

export default SolutionHeader;
