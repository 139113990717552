import logo from './logo.svg';
import './App.css';
import HomeScreen from './Screens/HomeScreen';
import Solutions from './Screens/Solutions';
import Blog from './Screens/Blog';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from './Components/Header';
import NewHeader from './Components/NewHeader/NewHeader';
import TryDemoScreen from './Screens/TryDemoScreen';
import SecondHeader from './Components/SecondHeader/SecondHeader';
import WhyUseScreen from './Screens/WhyUseScreen';
import Footer from './Components/Footer/Footer';
import CompanyScreen from "./Screens/CompanyScreen";
const App = () => {
  return (
    <Router>
      <SecondHeader />
     <main>
     <Routes>
     <Route path="/" element={<HomeScreen />} exact />
     <Route path="/solutions" element={<Solutions />} exact />
     <Route path="/blog" element={<Blog />} exact />
     <Route path="/demo" element={<TryDemoScreen />} exact />
     <Route path="/whyuse" element={<WhyUseScreen />} exact />
     <Route path="/company" element={<CompanyScreen />} exact />
     </Routes>
     <Footer />
     </main>
    </Router>
  );
}

export default App;
