import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import "./CustomInput.css"; // Vaši prilagođeni stilovi

const CustomInput = ({ type = "text", name, placeholder, value, onChange }) => {
  const icon = type === "text" ? faUser : faLock;
  const iconColor = "#00C613";
  return (
    <div className="custom-input-container">
      <FontAwesomeIcon icon={icon} className="icon" style={{ color: iconColor, fontSize: "24px", marginLeft: "5px" }} />
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="custom-input" // Dodajte vaše prilagođene klase za stilizovanje
      />
    </div>
  );
};

export default CustomInput;
