import React from 'react';
import WhyUseHeader from '../Components/WhyUse/WhyUseHeader';
import PlanProjects from '../Components/WhyUse/PlanProjects';
import AlignTeams from '../Components/WhyUse/AlignTeams';
import TrackProgress from '../Components/WhyUse/TrackProgress';
import Features from '../Components/WhyUse/Features';
import Card from '../Components/WhyUse/Card';
import { Row, Col, Container } from 'react-bootstrap';
import CustomButton from '../Components/CustomButton';
import { useTranslation } from 'react-i18next';
import COFCTA from "../Components/COFCTA"
import styles from "./WhyUse.module.css"
const WhyUseScreen = () => {
    const { t } = useTranslation();
    const image1 = "./images/logosimple.PNG";
    return (
        <div>
            <div style={{ backgroundColor: "rgb(14,55,71)"}}><WhyUseHeader /></div>
           <div style={{backgroundColor: "#1d4e5b"}}><PlanProjects /></div> 
           <div style={{backgroundColor:"rgb(14,55,71)"}}><AlignTeams /></div> 
           <div style={{backgroundColor: "#1d4e5b"}}><TrackProgress /></div>
           <div style={{ backgroundColor: "rgb(14,55,71)"}}> <Features /></div>
           <div style={{
                    backgroundColor: "#1d4e5b",
                }}>
                    <COFCTA backgroundColor="#1d4e5b" />
            </div>
        </div>
    );
}

export default WhyUseScreen;
