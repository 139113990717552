import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchPosts } from "../actions/blogActions"; 

function Blog() {
  const dispatch = useDispatch(); // Dobavljanje dispečera

  const BlogPosts = useSelector((state) => state.posts);
  const { posts } = BlogPosts;

  useEffect(() => {
    // Pozivanje akcije fetchPosts koristeći dispatch
    dispatch(fetchPosts());
  }, [dispatch]); // Dodavanje dispečera kao zavisnosti useEffect-a

  return (
    <div>
      <h1>Blog Postovi</h1>
      <ul>
        {posts.map(post => (
          <li key={post.id}>
            <h2>{post.title.rendered}</h2>
            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Blog;
