import React from "react";
import CustomButton from "./CustomButton";
import styles from "./UsageCard.module.css";
import { useNavigate } from "react-router-dom";

const UsageCard = ({ header, text, buttonText, onButtonClick, imageSrc, isActive }) => {
  const handleClick = () => {
    if (onButtonClick) {
      onButtonClick();
    }
  };

  const navigate = useNavigate();
   const scrollToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: "smooth" // Opciono: pravi animirani scroll ka vrhu stranice
      });
   };

  return (
    <div className={`${isActive ? styles.active : styles.card}`} onClick={handleClick}>
      <div className={styles.header}>{header}</div>
      <div className={styles.text}>{text}</div>
      {isActive && <CustomButton label={buttonText} className="custom-button-primary" onClick={() => {
                     navigate('/whyuse'); // Navigacija na "/whyuse" rutu koristeći navigate
                     scrollToTop(); // Poziv funkcije za scroll na vrh stranice
                  }} />}
    </div>
  );
};

export default UsageCard;
