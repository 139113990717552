import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from "./TryDemoScreen.module.css"
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CustomInput from "../Components/customInput/CustomInput"
import CustomButton from "../Components/CustomButton"
import Cofcta from "../Components/COFCTA";
import { useTranslation } from "react-i18next";

const TryDemoScreen = () => {
    const [formData, setFormData] = useState({
        tip: "try Demo",
        firstName: "",
        lastName: "",
        email: "",
        language: "",
        message: ""
    });
    const { t } = useTranslation();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Pozovite servis za slanje podataka
            const response = await fetch("https://formspree.io/f/xnqebwrj", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error("Error sending data");
            }

            // Resetujte formu nakon slanja
            setFormData({
                tip: "try Demo",
                firstName: "",
                lastName: "",
                email: "",
                language: "",
                message: ""
            });

            // Dodajte kod za prikazivanje uspešne poruke ili preusmerenje korisnika
        } catch (error) {
            console.error("Error sending data:", error);
            // Dodajte kod za prikazivanje greške ili upravljanje greškom
        }
    };

    return (
        <div className={styles.color}>
        <div className={styles.demodiv}>
            <Row className={styles.reverseOnSmallScreen}>
                <Col xs={12} lg={6}>
                    <h1 className="text-center pt-4">{t("Zakazite probnu verziju")}!</h1>
                    <div className={styles.smalldiv}>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col className="py-1"><CustomInput
                                    type="text"
                                    name="firstName"
                                    placeholder={t("Vase ime")}
                                    value={formData.firstName}
                                    onChange={handleChange}
                                /></Col>
                                <Col className="py-1"><CustomInput
                                    type="text"
                                    name="lastName"
                                    placeholder={t("Vase prezime")}
                                    value={formData.lastName}
                                    onChange={handleChange}
                                /></Col>
                            </Row>
                            <Row>
                                <Col className="py-1">
                                    <CustomInput
                                        type="email"
                                        name="email"
                                        placeholder={t("Vasa email adresa")}
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="py-1">
                                    <select
                                        name="language"
                                        style={{
                                            backgroundColor: "#415f74",
                                            borderRadius: "0%",
                                            color: "white",
                                            border: "none",
                                            padding: "0.5rem 0rem",
                                            margin: "0.1rem 0rem",
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                        value={formData.language}
                                        onChange={handleChange}
                                    >
                                        <option value="razgovor na engleskom">{"I want my meet to be in english"}</option>
                                        <option value="razgovor na srpskom">{t("Zelim da moj sastanak bude na sprskom")}</option>
                                    </select>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="py-1">
                                    <textarea
                                        name="message"
                                        placeholder={t("Vasa poruka")}
                                        style={{
                                            height: '100px',
                                            backgroundColor: "#415f74",
                                            color: "white",
                                            border: "none",
                                            borderRadius: "0",
                                            margin: "0.1rem 0",
                                            padding: "0.5rem 0rem",
                                            width: "100%"
                                        }}
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>

                            <div className="d-grid gap-2 ">
                                <CustomButton label={t("Zakazite probnu verziju")} className="custom-button-primary" type="submit" />
                            </div>
                        </Form>
                    </div>
                </Col>
                <Col xs={12} lg={6} className="text-center">
                    <img src="/images/Asset20.png" alt="nema slike" className={styles.slika}></img>
                </Col>
            </Row>
            <Cofcta />
        </div>
        </div>
    )
}

export default TryDemoScreen;
