import React from 'react';
import './CoFButton.css'; // Stilizacija dugmeta

const CoFButton = ({ buttonText, hasBorder, borderColor, onClick }) => {
  const buttonClass = hasBorder ? 'custom-button with-border' : 'custom-button';

  const buttonStyle = hasBorder ? { border: `2px solid ${borderColor}` } : {};

  return (
    <button className={buttonClass} style={buttonStyle} onClick={onClick}>{ buttonText }</button>
  );
}

export default CoFButton;
