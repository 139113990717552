import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Card from "./Card";
import TextImageIsRight from "../Solutions/TextImageIsRight";
import TextLeftTwoImgRight from "../Solutions/TextLeftTwoImgRight"
import styles from "./AlignTeams.module.css"
const AlignTeams = () => {
  const { t } = useTranslation();

  const title1 = (
    <h2
      style={{
        color: "rgb(242, 242, 248)",
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "28px",
        paddingTop: "2rem",
        paddingLeft: "15px",
      }}
    >
      {t("Kako radi")}
    </h2>
  );
  const text1 = (
    <ul
      style={{
        color: "rgb(199, 198, 216)",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "30px",

      }}
    >
      <li>{t("Text1_Item1")}</li>
      <li>{t("Text1_Item2")}</li>
      <li>{t("Text1_Item3")}</li>
      <li>{t("Text1_Item4")}</li>
      <li>{t("Text1_Item5")}</li>
    </ul>
  );
  const image1 = "./images/dvatelefona.PNG";
  const imgScheduled = "/images/scheduled1.png";
  const imgNotifications = "/images/notifications.png";

  const titlesolution = (
    <h2 style={{ color: "rgb(0,198,19)", textAlign: "left", fontWeight: "700" }}>
      {t("Solution")}
    </h2>
  );
  const titleproblem = (
    <h2 style={{ color: "white", textAlign: "left", fontWeight: "700" }}>
      {t("Problem")}
    </h2>
  );
  const problemdescription = (
    <p style={{ color: "white", textAlign: "left", fontWeight: "500" }}>
      {t("Problem_Description")}
    </p>
  );
  const descriptionofsolution = (
    <p style={{ color: "white", textAlign: "left", fontWeight: "500" }}>
      {t("Solution_Description")}
    </p>
  );

  return (
    <div className={styles.mobilni}
      style={{


        backgroundColor: "rgb(14,55,71)",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          marginBottom: "30px",
          color: "rgb(255, 255, 255)",
          fontWeight: 700,
          fontSize: "34px",
          lineHeight: "41px",
        }}
      >
        {t("Section_Title")}
      </h2>

      <Row
        style={{
          justifyContent: "center",
          margin: "0 -10px",
          padding: "0",
          display: "flex",
          flexWrap: "wrap",

        }}
      >
        <Col
          xs={10}
          sm={6}
          md={4}
          className="card-container" /* Dodajemo klasu za stilove kartica */
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center", /* Horizontalno centriranje kartica */
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Card title={titleproblem} description={problemdescription} backgroundColor={"#1d4e5b"} />
        </Col>
        <Col
          xs={10}
          sm={6}
          md={4}
          className="card-container" /* Dodajemo klasu za stilove kartica */
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center", /* Horizontalno centriranje kartica */
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Card title={titlesolution} description={descriptionofsolution} backgroundColor={"#1d4e5b"} />
        </Col>

      </Row>




      <TextLeftTwoImgRight title={title1} text={text1} imageOne={imgScheduled} imageTwo={imgNotifications} showButton={false} />
    </div>
  );
};

export default AlignTeams;
