import React from "react";

const TeamProfiles = ({ header, text, imageUrl }) => {
  return (
    <div style={styles.card}>
      <img src={imageUrl} alt="Profile" style={styles.image} />
      <div style={styles.contentwrapper}>
      <div style={styles.content}>
        <div style={styles.header}>{header}</div>
        <div style={styles.text}>{text}</div>
      </div>
      </div>
    </div>
  );
};

const styles = {
  card: {
    padding: "1rem 1.5rem",
    margin: "0px",
    color: "whitesmoke",
  },
  image: {
    width: "130px", // Adjust the width as needed
    height: "auto", // Maintain the aspect ratio
    marginBottom: "8px",
    borderRadius: "8px", // Optional: Add border-radius for rounded corners
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Align items to the start of the container
    
  },
  contentwrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Align items to the start of the container
  },
  header: {
    fontSize: "25px",
    fontWeight: "bold",
    marginBottom: "8px", // Add margin below the header
  },
  text: {
    fontSize: "1.6rem",
    fontWeight: "400",
    color: "grey",
  },
};

export default TeamProfiles;
