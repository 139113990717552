import React from 'react';
import PropTypes from 'prop-types';
import "./CustomButton.css";

const CustomButton = ({ onClick, label, className }) => {
  return (
    <button className={`custombutton ${className}`} onClick={onClick}>
      <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{label}</span>
    </button>
  );
};

CustomButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CustomButton;
