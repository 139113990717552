import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import styles from "./CompanyScreen.module.css"
import KeyValuesCard from "../Components/KeyValuesCard";
import StoryComponent from "../Components/StoryComponent";
import TeamProfiles from "../Components/TeamProfiles";
import CustomInput from "../Components/customInput/CustomInput";
import CustomButton from "../Components/CustomButton";
import { useTranslation } from "react-i18next";

const CompanyScreen = () => {
    const { t } = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const ideja = (
        <h>
            {t("Ideja")}
        </h>);
    const idejatext = (
        <p style={{}}>
            {t("Kroz rad u fudbalu")}
        </p>
    );
    const prepoznavanjeProblema = (
        <h>
            {t("Prepoznavanje problema")}
        </h>);
    const prepoznavanjeProblematxt = (
        <p>
            {t("Način funkcionisanja")}
        </p>
    );

    const Istraživanje = (
        <h>
            {t("Istraživanje načina organizovanja fudbalski klubova")}
        </h>);
    const Istraživanjetxt = (
        <p>
            {t("Na osnovu empirijskog")}
        </p>
    );
    const PrviKonkretniKoraci = (
        <h>
            {t("Prvi konkretni koraci")}
        </h>);
    const PrviKonkretniKoracitxt = (
        <p>
            {t("2022. godine")}
        </p>
    );
    const Odideje = (
        <h>
            {t("Od ideje do realizacije")}
        </h>);
    const Odidejetxt = (
        <p>
            {t("Početkom 2023.")}
        </p>
    );
    const resenje = (
        <h>
            {t("Resenje")}
        </h>);
    const resenjetxt = (
        <p>
            {t("Danas je")}
        </p>
    );
    const [formData, setFormData] = useState({
        tip: "submit form",
        role: "",
        email: ""

    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Pozovite servis za slanje podataka
            const response = await fetch("https://formspree.io/f/xnqebwrj", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error("Error sending data");
            }

            // Resetujte formu nakon slanja
            setFormData({
                tip: "submit form",
                email: "",
                role: ""
            });
            setSubmitted(true);
            // Dodajte kod za prikazivanje uspešne poruke ili preusmerenje korisnika
        } catch (error) {
            console.error("Error sending data:", error);
            // Dodajte kod za prikazivanje greške ili upravljanje greškom
        }
    };
    return (
        <div>
            <div style={{ backgroundColor: "#0e3747" }}>
            <div className={styles.paddin} style={{ backgroundColor: "#0e3747" }}>
                <Row className={styles.herorow}>
                    <Col xl={7} className={styles.ccol1}>
                        <h1 className={styles.wearecof} >
                            <span style={{ color: "white" }}>{t("Mi smo")}</span> <br />
                            <span style={{ color: "green" }}>Concierge Of Football</span>
                            <hr style={{ color: "green", borderBottom: "2px solid green", width: "10rem"}} />
                        </h1>
                    </Col>
                    <Col xl={5} className={styles.colimg}>
                        <img className={styles.cofimage} src="/images/LOGOJPEG.png" alt="Logo"  />
                    </Col>
                </Row>
            </div>
            </div>


            <div style={{backgroundColor:"#0e3747"}}>
            <div className={styles.valuecomponent}>
                <Row className={styles.valuestext}><h1 className={styles.tezimo} style={{textAlign:"left"}}>{t("Ovo su kljucne vrednosti kojima tezimo")}</h1></Row>
                <Row className={styles.keyvalues} style={{ paddingRight: "0 !important", paddingLeft: "0 !important" }}>
                    <Col lg={3} xs={12} style={{ paddingRight: "0 !important", paddingLeft: "0 !important" }}><KeyValuesCard titleColor={"green"} header={t("Izadji iz zone konfora.")} text={t("Zajedno prepoznajmo i osnazimo potencijale koje posedujemo. Otkrij mogucnosti za licni i timski rast.")} /></Col>
                    <Col lg={3} xs={12} style={{ paddingRight: "0 !important", paddingLeft: "0 !important" }}><KeyValuesCard titleColor={"yellow"} header={t("Budi strastven.")} text={t("Unesi ljubav i strast u posao na pravi nacin. Ostvari uspeh uzivajuci u radu i belezi impresivne rezultate.")} /></Col>
                    <Col lg={3} xs={12} style={{ paddingRight: "0 !important", paddingLeft: "0 !important" }}><KeyValuesCard titleColor={"blue"} header={t("Gradi poverenje iskrenoscu.")} text={t("Stvori neraskidivu vezu zasnovanu na poverenju i iskrenosti uz postovanje individualnih i timskih potreba.")} /></Col>
                    <Col lg={3} xs={12} style={{ paddingRight: "0 !important", paddingLeft: "0 !important" }}><KeyValuesCard titleColor={"#03f4fc"} header={t("Budi proaktivan.")} text={t("Deluj preventivno, predupredi probleme, fokusiraj se na resenja. Resite vase zadatke jer samo tako postajete funkcionalni.")} /></Col>
                </Row>
            </div >
            </div>

            <div style={{backgroundColor:"#1d4e5b"}}>
            <div className={styles.ourstorywraper}>
                <div className={styles.ourstory}>
                    <div className={styles.flexContainer}>
                        <h2 className={styles.storytitle}>
                            {t("Pomazemo vam da podignete")}{" "}
                            <span style={{ color: "#00c613" }}>
                                {t("vas klub na visi nivo")}
                            </span>
                        </h2>
                    </div>

                    <div  className={styles.timelineseparator} ></div>
                    <h5 style={{ color: "white" }} className={styles.storytext}> {t("A ovo je nasa prica")}</h5>



                    <Row className={styles.storyfirstrow}>
                        <Col lg={3} xs={12} ><StoryComponent icon="/images/ZnakUzvika.png" header={ideja} text={idejatext} /></Col>
                        <Col className={styles.arrowimagecontainer}> <img src="/images/Asset23.png" className={styles.arrowimg}></img></Col>
                        <Col lg={3} xs={12}><StoryComponent icon="/images/CheckList.png" header={prepoznavanjeProblema} text={prepoznavanjeProblematxt} /></Col>
                        <Col className={styles.arrowimagecontainer}><img src="/images/Asset23.png" className={styles.arrowimg}></img></Col>
                        <Col lg={3} xs={12}><StoryComponent icon="/images/LupaIkona.png" header={Istraživanje} text={Istraživanjetxt} /></Col>
                    </Row>
                    <Row className={styles.arrowrow}>
                        <Col><img src="/images/Asset24.png" className={styles.arrowimgg}></img></Col>
                    </Row>
                    <Row className={styles.storyfirstrow}>
                    <Col lg={3}><StoryComponent icon="/images/StreliceIkona.png" header={resenje} text={resenjetxt} /></Col>
                        <Col className={styles.imagerow}><img src="/images/Levastrelica.png" className={styles.arrowimg} ></img></Col>
                        <Col lg={3}><StoryComponent icon="/images/SijalicaIkona.png" header={Odideje} text={Odidejetxt} /></Col>
                        <Col className={styles.imagerow}><img src="/images/Levastrelica.png" className={styles.arrowimg}></img></Col>
                        <Col lg={3}><StoryComponent icon="/images/CheckIkona.png" header={PrviKonkretniKoraci} text={PrviKonkretniKoracitxt} /></Col>
                       
                    </Row>
                </div>
            </div>
            </div>

            <div style={{backgroundColor:"#0e3747"}}>
            <div className={styles.teamwraper}>
                <div className={styles.team}>
                    <h2 className={styles.teamheader}>{t("Upoznajte nas tim")}</h2>
                    <div  className={styles.ourteamseparator} ></div>
                    <Row className={styles.teamrow} >
                        <Col xl={4} xs={12} className={styles.teamcol}><TeamProfiles header={"Marko Stanković"} text={"CTO"} imageUrl="/images/man.png" /></Col>
                        <Col xl={4} xs={12} className={styles.teamcol}><TeamProfiles header={"Boris Miletić"} text={"CEO"} imageUrl="/images/profile.png" /></Col>
                        <Col xl={4} xs={12} className={styles.teamcol}><TeamProfiles header={"Sabrina Buljubasić"} text={t("Sales manager")} imageUrl="/images/woman.png" /></Col>
                    </Row>
                    <Row className={styles.teamrow} >
                        <Col xl={4} xs={12} className={styles.teamcol}><TeamProfiles header={"Dušan Miškulin"} text={"Business Developer"} imageUrl="/images/profile.png" /></Col>
                        <Col xl={4} xs={12} className={styles.teamcol}><TeamProfiles header={"Milan Jovanović"} text={"Frontend Developer"} imageUrl="/images/man.png" /></Col>
                        <Col xl={4} xs={12} className={styles.teamcol}><TeamProfiles header={"Joviša Janić"} text={t("Frontend Developer")} imageUrl="/images/man.png" /></Col>
                    </Row>
                    <Row className={styles.teamrow} >
                        <Col xl={4} xs={12} className={styles.teamcol}><TeamProfiles header={"Bogoje Bojović"} text={"Web Designer"} imageUrl="/images/man.png" /></Col>
                        <Col xl={4} xs={12} className={styles.teamcol}><TeamProfiles header={"Aleksandar Mitrović"} text={"DevOps"} imageUrl="/images/profile.png" /></Col>
                    </Row>
                </div>
            </div>
            </div>
            
            <div style={{backgroundColor:"#1d4e5b"}}>
            <div className={styles.ourstorywraper}>
                <div className={styles.newsletter}>
                    <Row >
                        <Col xl={6} className={styles.headercol}>
                            <h2 className={styles.newsheader}>{t("newsletter_title")}</h2>
                            <div style={{ borderBottom: "2px solid #00c613", width: "30%", paddingTop: "0.25rem", marginBottom: "0.75rem" }}></div>
                            <h5 className={styles.headertext}>{t("newsletter_subtitle")}</h5>
                        </Col>
                        <Col className={styles.newsinputs} lg={6} xs={12}>
                            <Row className="align-items-stretch"> {/* Dodajemo klasu align-items-stretch da bismo obezbedili da obe kolone budu iste visine */}
                                <Col lg={6} xs={12} className="mb-3"> {/* Dodajemo klasu mb-3 za dodavanje margine između kolona */}
                                    <CustomInput
                                        type="email"
                                        name="email"
                                        placeholder={t("Unesite email adresu")}
                                        onChange={handleChange}
                                        className="h-95"
                                    />
                                </Col>
                                <Col lg={6} xs={12} className="mb-3"> {/* Dodajemo klasu mb-3 za dodavanje margine između kolona */}
                                    <select
                                        name="role"
                                        placeholder={t("Odaberite poziciju")}
                                        style={{
                                            backgroundColor: "#415f74",
                                            borderRadius: "0%",
                                            color: "white",
                                            border: "none",
                                            padding: "0.5rem 0rem",
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                        value={formData.role}
                                        onChange={handleChange}
                                        className="h-100"
                                    >
                                        <option value="">{t("Odaberite poziciju")}</option>
                                        <option value="Trener">{t("Trener")}</option>
                                        <option value="Igrac">{t("Igrac")}</option>
                                        <option value="Vlasnik kluba">{t("Vlasnik kluba")}</option>
                                    </select>
                                </Col>
                            </Row>


                            <h6 className={styles.inputstext}> {t("newsletter_terms")}
                            </h6>
                            <CustomButton
                                label={t("newsletter-subscription")}
                                className="custom-button-primary my-3 w-100"
                                onClick={handleSubmit}
                            />
                            {submitted && <p style={{ color: "green" }}>Successfully submitted!</p>}
                        </Col>
                    </Row>
                </div>
            </div>
            </div>   

        </div>

    )
}

export default CompanyScreen;