import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import CustomButton from '../CustomButton';
import styles from './TextImageLeft.module.css';
import { useTranslation } from "react-i18next";
const TextTwoImageleft = ({ title, text, image1, image2 }) => {
    const { t } = useTranslation();

    const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;
    const signUpUrl = `${appBaseUrl}/signup`;
    
    const handleSignUpClick = () => {
      // Preusmeravanje na stranicu za registraciju
      window.location.href = signUpUrl;
    };
    return (
        <div className={styles.res100}>
        <Row className="justify-content-center mt-3">
            {/* Leva strana sa tekstom */}
            <Col xs={12} md={5} className="mb-3 mb-md-0">
                <Container className={styles.textContainer}>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.text}>{text}</p>
                    <CustomButton label={t("Prijavite_se_besplatno")} className="custom-button-primary" onClick={handleSignUpClick}></CustomButton>
                </Container>
            </Col>
            {/* Desna strana sa slikom */}
            <Col xs={12} md={5} className="text-center">
                <Row>
                    <Col xs={6}>
                        <img src={image1} alt="Image" className={styles.twoimage} />
                    </Col>
                    <Col  xs={6} style={{ margin: '0 auto', marginLeft:"-35px" }}>
                        <img src={image2} alt="Image" className={styles.twoimage}  />
                    </Col>
                </Row>
            </Col>
        </Row>
        </div>
    );
};

export default TextTwoImageleft;
