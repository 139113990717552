import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./COFCTA.module.css";
import CustomButton from "./CustomButton";
import { useTranslation } from "react-i18next";

const Cofcta = ({ backgroundColor, textColor }) => {
  const { t } = useTranslation();
  const logo = "./images/justlogo.png";
  //<Cofcta backgroundColor="#f5f5f5" textColor="#333333" /> ovako se prosledjuje text color i background komponenti

  const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;

  // Dodajte putanje za prijavu i registraciju na base URL
  const signUpUrl = `${appBaseUrl}/signup`;
  const signInUrl = `${appBaseUrl}/signin`;
  
  // Handler funkcija za klik na dugme "Sign In"
  const handleSignUpClick = () => {
    // Preusmeravanje na stranicu za registraciju
    window.location.href = signUpUrl;
  };
  
  const handleSignInClick = () => {
    // Preusmeravanje na stranicu za prijavljivanje
    window.location.href = signInUrl;
  };
  return (
    <div className={styles.cta}
      style={{
        backgroundColor: backgroundColor || "rgb(14,55,71)", // Koristimo backgroundColor prop ako je dostupan, inače koristimo podrazumevanu boju
        color: textColor || "#f5f5f5", // Koristimo textColor prop ako je dostupan, inače koristimo podrazumevanu boju
      }}
    >
      <div>
        <img className={styles.logo} src={logo} alt="Image" />
        <h1
          style={{
            paddingBottom: "30px",
            fontSize: "36px",
            fontWeight: "500",
            lineHeight: "46px",
            paddingBottom: "40px",
          }}
        >
          {t("Automatizuj_PTO_praćenje")}
          <br />
          {t("team_worldwide")}
        </h1>
        <CustomButton
          label={t("Prijavite_se_besplatno")}
          className="custom-button-primary"
          onClick={handleSignUpClick}
        ></CustomButton>
        <p style={{ paddingTop: "10px" }}>
          {t("Isprobaj sve funkcionalnosti aplikacije 60 dana besplatno.")}
        </p>
      </div>
    </div>
  );
};

export default Cofcta;
