import React from "react";
import { useTranslation } from "react-i18next";
import Image1 from "./images/calendar.png";
import Image2 from "./images/Nalog1.png"; // Dodajte import za drugu sliku
import Image3 from "./images/matchday.PNG"; // Dodajte import za drugu sliku
import { Row, Col, Card } from "react-bootstrap";
import styles from "./AlignTeams.module.css"
const WhyUseHeader = () => {
  const { t } = useTranslation();
  
  return (
    <div
      style={{
        backgroundColor: "rgb(14,55,71)",
        paddingBottom: "60px",
      }}
      className={`px-0 px-md-5 ${styles.phoneHero}`}
    >
      <Row style={{ paddingBottom: "2rem" }}>
        <Col className={styles.whyHero}
          xs={12}
          md={6}
        >
          {" "}
          {/* Smanjujemo širinu teksta */}
          <div>
            <h1 className={styles.whyHeroTitle}
              style={{
               
                color: "rgb(255, 255, 255)",
              }}
            >
              {t("Zašto koristiti")}<br></br>
              <span className={styles.spancof} style={{ color: "#1af330" }}>Concierge Of Football?</span>
            </h1>

            <p
              style={{
                color: "rgb(199, 198, 216)",
                fontSize: "22px",
                lineHeight: "35px",
                fontSize: "16px",
                lineHeight: "1.5",
                borderTop: "2px solid rgb(0, 198, 19)", // Dodajte ovu liniju za gornju granicu
                paddingTop: "1.2rem" // Dodajte malo razmaka iznad teksta
              }}
            >
              {t("Fudbalski klub je organizam koji boluje ukoliko neki od organa ne funkcioniše, što stvara haotičan sistem opterećen problemima koji usporavaju rast. Rešite se bespotrebnog trošenja energije i vremena na usklađivanje individualnih i timskih obaveza pomoću štapa i kanapa (whatsapp, viber, e-mail, face to face). Od komplikovanog i tromog sistema stvorite jednostavan, efikasan i sinhronizovan sistem koji zadovoljavajuci individualne potrebe stvara timski uspeh.")}
            </p>
            <p className={styles.cofpomoci} >
              {t("Cof vam moze pomoci")}
            </p>
            
          </div>
        </Col>
        <Col className={`text-center ${styles.twopfones}`}
          xs={12}
          md={6}
        >
          {/* Druga kolona sa 4 kolona širine na srednjim i većim ekranima */}
          <Row>
            <Col  xs={6} className="text-center">
              <img className={styles.twoimage}
                src={Image1}
                alt="Slika 1"
              />
            </Col>
            <Col  xs={6} className={`text-center ${styles.imageForHeroTwo}`}>
              <img className={styles.twoimage}
                src={Image2}
                alt="Slika 2"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={4} className="d-flex justify-content-center" style={{ marginBottom: '10px' }}>
          <Card
            className="text-center"
            style={{
              backgroundColor: "rgb(0,198,19)",
              borderRadius: 0,
              width: "350px",
              height: "50px", // Postavljamo visinu kartice na 200px
              display: "flex", // Koristimo fleksibilni prikaz
              justifyContent: "center", // Horizontalno centriranje sadržaja
              alignItems: "center", // Vertikalno centriranje sadržaja
              position: "static",
            }}
          >
            <Card.Body>
              <Card.Title style={{fontWeight:"600", alignItems:"center", paddingTop:"5px"}}>{t("Planiranje obaveza")}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4} className="d-flex justify-content-center" style={{ marginBottom: '10px' }}>
          <Card
            className="text-center"
            style={{
              backgroundColor: "rgb(0,198,19)",
              borderRadius: 0,
              width: "350px",
              height: "50px", // Postavljamo visinu kartice na 200px
              display: "flex", // Koristimo fleksibilni prikaz
              justifyContent: "center", // Horizontalno centriranje sadržaja
              alignItems: "center", // Vertikalno centriranje sadržaja
              position: "static",
            }}
          >
            <Card.Body>
              <Card.Title style={{fontWeight:"600", alignItems:"center", paddingTop:"5px"}}>{t("Sinhronizacija tima")}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4} className="d-flex justify-content-center" style={{ marginBottom: '10px' }}>
          <Card
            className="text-center"
            style={{
              backgroundColor: "rgb(0,198,19)",
              borderRadius: 0,
              width: "350px",
              height: "50px", // Postavljamo visinu kartice na 200px
              display: "flex", // Koristimo fleksibilni prikaz
              justifyContent: "center", // Horizontalno centriranje sadržaja
              alignItems: "center", // Vertikalno centriranje sadržaja
              position: "static",
            }}
          >
            <Card.Body>
              <Card.Title style={{fontWeight:"600", alignItems:"center", paddingTop:"5px"}}>{t("Praćenje napretka")}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default WhyUseHeader;
