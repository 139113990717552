import React from "react";
import CustomButton from "./CustomButton";
import { Row, Col } from "react-bootstrap";
import styles from "./OrganizationCard.module.css"; // Import CSS module

const OrganizationCard = ({ header, text, buttonText, onButtonClick, imageSrc }) => {
  return (
    <div className={styles.card}> {/* Use className instead of style */}
      <Row><div className={styles.header}>{header}</div></Row>
      <Row className={styles.row}>
        <Col lg={8} md={8} xs={8} className={styles.col}>

          <div className={styles.text}>{text}</div>

        </Col>
        <Col className={styles.imagecol} lg={4} md={4} xs={4}>
          <img src={imageSrc} className={styles.image} alt="Organization Image" />
        </Col>
      </Row>
      <Row ><div><CustomButton label={buttonText} className={styles.dugme} onClick={onButtonClick} /></div>
      </Row>


    </div>
  );
};

export default OrganizationCard;
