import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import CustomButton from '../CustomButton';
import styles from './TextImageIsRight.module.css';
import { useTranslation } from "react-i18next";
const TextImageIsRight = ({ title, text, image, showButton = true, imageStyle }) => {
    const { t } = useTranslation();

    const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;
const signUpUrl = `${appBaseUrl}/signup`;

const handleSignUpClick = () => {
  // Preusmeravanje na stranicu za registraciju
  window.location.href = signUpUrl;
};
    return (
        <div className={styles.res100}>
        <Row className={`justify-content-center mt-3 ${styles.reverseOnSmallScreen}`}>
            {/* Leva strana sa slikom */}
            <Col xs={12} md={5} className="text-center mb-3 mb-md-0">
                <img src={image} alt="Image" style={imageStyle} className={styles.image} />
            </Col>
            {/* Desna strana sa tekstom */}
            <Col xs={12} md={5} className="mb-3 mb-md-0">
                <Container className={styles.textContainer}>
                    <h1 className={styles.title}>{title}</h1>
                    <p className={styles.text}>{text}</p>
                    {showButton && <CustomButton label={t("Prijavite_se_besplatno")} className="custom-button-primary" onClick={handleSignUpClick}></CustomButton>}
                </Container>
            </Col>
        </Row>
        </div>
    );
};

export default TextImageIsRight;
