import React from "react";
import { Row, Col } from "react-bootstrap";
import Card from "./Card";
import TextImageIsRight from "../Solutions/TextImageIsRight";
import { useTranslation } from "react-i18next";
import styles from "./AlignTeams.module.css"
const TrackProgress = () => {
  const { t } = useTranslation();

  const title1 = (
    <h2
      style={{
        color: "rgb(242, 242, 248)",
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "28px",
        paddingTop:"2rem",
        paddingLeft: "15px",
      }}
    >
      {t("Kako radi")}
    </h2>
  );

  const text1 = (
    <ul
    style={{
      color: "rgb(199, 198, 216)",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "30px",
      
    }}
    >
      <li>
        {t(
          "Dnevno i nedeljno (po potrebi) izveštavanje o preuzetim i izvršenim obavezama."
        )}
      </li>
      <li>
        {t(
          "Automatsko upozorenje u slučaju učestale pojave istovetnog problema."
        )}
      </li>
      <li>
        {t("Filtriranje prema vrsti i učestalosti problema koji se javljaju.")}
      </li>
      <li>
        {t(
          "Prevencija povreda kroz blagovremeno otkrivanje anomalija u procesu rada."
        )}
      </li>
      <li>{t("Kvalitetan i brz proces oporavka.")}</li>
    </ul>
  );

  const image1 = "./images/dvatelefona.png";
  const titlesolution = (
    <h2 style={{ color: "rgb(0,198,19)", textAlign: "left",fontWeight:"700" }}>
      {t("Solution")}
    </h2>
  );
  const titleproblem = (
    <h2 style={{ color: "white", textAlign: "left", fontWeight:"700" }}>
      {t("Problem")}
    </h2>
  );
  const problemdescription = (
    <p style={{ color: "white", textAlign: "left", fontWeight:"500" }}>
      {t("90% fudbalskih klubova ne zna trenutni status procesa unutar kluba.")}
    </p>
  );
  const descriptionofsolution = (
    <p style={{ color: "white", textAlign: "left", fontWeight:"500" }}>
      {t(
        "Sistematizujte svoje zadatke sa mogućnošću izvršenja preseka statusa u svakom trenutku."
      )}
    </p>
  );

  return (
    <div className={styles.mobilni}
      style={{
        backgroundColor: "#1d4e5b",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          marginBottom: "30px",
          color: "rgb(255, 255, 255)",
          fontWeight: 700,
          fontSize: "34px",
          lineHeight: "41px",
        }}
      >
        {t("Svakodnevno pratite napredak")}
      </h2>
      <Row
        style={{
          justifyContent: "center",
          margin: "0 -10px",
          padding: "0",
          display: "flex",
          flexWrap: "wrap",
          
        }}
      >
       <Col
  xs={10}
  sm={6}
  md={4}
  className="card-container" /* Dodajemo klasu za stilove kartica */
  style={{
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center", /* Horizontalno centriranje kartica */
    paddingLeft: "10px",
    paddingRight: "10px",
  }}
>
  <Card title={titleproblem} description={problemdescription} />
</Col>
<Col
  xs={10}
  sm={6}
  md={4}
  className="card-container" /* Dodajemo klasu za stilove kartica */
  style={{
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center", /* Horizontalno centriranje kartica */
    paddingLeft: "10px",
    paddingRight: "10px",
  }}
>
  <Card title={titlesolution} description={descriptionofsolution} />
</Col>

      </Row>
      <TextImageIsRight
        title={title1}
        text={text1}
        image={image1}
        showButton={false}
      />
    </div>
  );
};

export default TrackProgress;
