import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import UsageCard from "./UsageCard";
import CustomButton from "./CustomButton";
import { useTranslation } from "react-i18next";
import styles from "./UseCases.module.css";
import { useNavigate } from "react-router-dom";

const UseCases = () => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState("/images/laptop.png"); // Initial image
  const [activeCard, setActiveCard] = useState(0); // Initial active card index

  const handleCardClick = (index, imageSrc) => {
    setSelectedImage(imageSrc); // Update selected image
    setActiveCard(index); // Update active card index
  };

  const navigate = useNavigate();
   const scrollToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: "smooth" // Opciono: pravi animirani scroll ka vrhu stranice
      });
   };

  return (
    <div className={styles.kontejnerwraper}>
      <div className={styles.kontejner}>
        <h2 className={styles.naslov}>{t("Upotreba COF aplikacije")}</h2>
        <h4 className={styles.tekst}>
          {t(
            "CoF omogucava protok informacija, preglednost i kontrolu unutar kluba. Igraci imaju jasan pregled svojh obaveza. Strucni stab lako i precizno planira obaveze. Uprava kluba ima blagovremen i tacan uvid u sve klupske aktivnosti."
          )}
        </h4>
        <Row className={styles.red}>
          <Col lg={8} xs={12} className={styles.imagecolumn}>
            <img
              alt="nema slike"
              src={selectedImage}
              className={styles.slika}
            ></img>
          </Col>
          <Col lg={4} xs={12} className={styles.cardrow}>
            <UsageCard
              header={t("Igrac")}
              text={t(
                "Potpuna preglednost i blagovremena obavestenja o klupskim obavezama."
              )}
              buttonText={t("Saznaj vise")}
              onButtonClick={() => handleCardClick(0, "/images/LaptopIgrac.png")}
              isActive={activeCard === 0}
            />
            <UsageCard
              header={t("Strucni stab")}
              text={t(
                "Kreiranje svakodnevnih aktivnosti i uvid u dnevno psiho-fizicko stanje igraca."
              )}
              buttonText={t("Saznaj vise")}
              onButtonClick={() => handleCardClick(1, "/images/LaptopTrener.png")}
              isActive={activeCard === 1}
            />
            <UsageCard
              header={t("Uprava")}
              text={t("Uvid u sve klupske procese u realnom vremenu.")}
              buttonText={t("Saznaj vise")}
              onButtonClick={() => handleCardClick(2, "/images/LaptopVlasnik.png")}
              isActive={activeCard === 2}
              
            />
          </Col>
        </Row>
        <div className={styles.buttondiv}>
          <CustomButton
            label={t("Pogledajte sve primere")}
            className="custom-button-primary"
            onClick={() => {
              navigate('/whyuse'); // Navigacija na "/whyuse" rutu koristeći navigate
              scrollToTop(); // Poziv funkcije za scroll na vrh stranice
           }}
          />
        </div>
      </div>
    </div>
  );
};

export default UseCases;
