import React from 'react';
import { Link } from "react-router-dom"; // Dodato za navigaciju
import { Button, Row, Col, Dropdown  } from "react-bootstrap";
import CoFButton from '../Button/CoFButton.js'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';
const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const handleDemoClick = () => {
    // Navigacija na novu stranicu kada se klikne na "Get a Demo"
    navigate('/demo');
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Dodajte 'smooth' za glatko pomeranje
    });
  };

const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;

// Dodajte putanje za prijavu i registraciju na base URL
const signUpUrl = `${appBaseUrl}/signup`;
const signInUrl = `${appBaseUrl}/signin`;

// Handler funkcija za klik na dugme "Sign In"
const handleSignUpClick = () => {
  // Preusmeravanje na stranicu za registraciju
  window.location.href = signUpUrl;
};

const handleSignInClick = () => {
  // Preusmeravanje na stranicu za prijavljivanje
  window.location.href = signInUrl;
};
  
  return (
    <footer className=" container-fluid text-center  pt-4" style={{ backgroundColor: '#29335C', color: 'white' }}>
    <div className="container mx-auto row justify-content-between align-items-center">
      {/* Prva vrsta footera */}
      <Col xl={3}  xs={12}>
        <div className="footer-section align-self-start" style={{ textAlign: 'left' }}>
          <Link to="/">
            <img src="/images/logo.png" alt="Logo" style={{ height: "45px" }} />
          </Link>
        </div>
      </Col>
      <Col xl={9}  xs={12} className="d-flex align-items-center justify-content-end">



        <CoFButton buttonText={t("Uloguj se")} onClick={handleSignInClick} />
        <div style={{ width: "30px" }} /> {/* Dodati razmak između dugmadi */}
        <CoFButton buttonText={t("Registruj se" )} onClick={handleSignUpClick}/>
        <div style={{ width: "30px" }} /> {/* Dodati razmak između dugmadi */}
        <CoFButton buttonText={t("Zakazi demo")} onClick={handleDemoClick} hasBorder={true} borderColor="green" />
      </Col>
    </div>

    <div className="container mx-auto row justify-content-start mt-1">
      <p style={{ textAlign: 'left', color: '#78789A' }}>{t("Aplikacija za kominikaciju i organizaciju unutar fudbalskih klubova")}</p>
    </div>

      <div className="row container mx-auto text-left">
        {/* Druga vrsta footera */}
        <div className="col-md-3" style={{ textAlign: 'left' }}>
          <div className="footer-section">
            <h5 className="font-weight-bold">{t("Proizvod")}</h5>
            <ul className="list-unstyled">
              <li><a href="/solutions" style={{ textDecoration: 'none', color: '#78789A'}}>{t("Upotreba")}</a></li>
              <li><a href="/whyuse" style={{ textDecoration: 'none', color: '#78789A'}}>{t("Zasto COF")}</a></li>
              {/* Dodajte više opcija po potrebi */}
            </ul>
          </div>
        </div>
        <div className="col-md-3" style={{ textAlign: 'left' }}>
          <div className="footer-section">
            <h5 className="font-weight-bold">{t("Resursi")}</h5>
            <ul className="list-unstyled">
              <li><a href="#" style={{ textDecoration: 'none', color: '#78789A'}}>{t("Informacije")}</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#78789A'}}>{t("Blog")}</a></li>
              <li><a href="#" style={{ textDecoration: 'none', color: '#78789A'}}>{t("Tutorijali")}</a></li>
              {/* Dodajte više opcija po potrebi */}
            </ul>
          </div>
        </div>
        <div className="col-md-3" style={{ textAlign: 'left' }}>
          <div className="footer-section">
            <h5 className="font-weight-bold">{t("Kompanija")}</h5>
            <ul className="list-unstyled">
              <li><a href="/company" style={{ textDecoration: 'none', color: '#78789A'}}>{t("Onama")}</a></li>
              <li><a href="/company" style={{ textDecoration: 'none', color: '#78789A'}}>{t("Nasa putanja")}</a></li>
              {/* Dodajte više opcija po potrebi */}
            </ul>
          </div>
        </div>
        <div className="col-md-3" style={{ textAlign: 'left' }}>
          <div className="footer-section">
            <h5 className="font-weight-bold">{t("Zapratite nas")}</h5>
            <ul className="social-icons list-inline">
              <li className="list-inline-item"><a href="link-to-fb" className="text-white"><i className="fab fa-facebook"></i></a></li>
              <li className="list-inline-item"><a href="link-to-instagram" className="text-white"><i className="fab fa-instagram"></i></a></li>
              <li className="list-inline-item"><a href="link-to-youtube" className="text-white"><i className="fab fa-youtube"></i></a></li>
              {/* Dodajte ikonice za ostale socijalne mreže */}
            </ul>
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="outline-light" id="language-dropdown">
              {t("Jezik")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1" onClick={() => changeLanguage('en')}>{t("Engleski")}</Dropdown.Item>
              <Dropdown.Item href="#/action-2" onClick={() => changeLanguage('srb')}>{t("Srpski")}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
