import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import OrganizationCard from "./OrganizationCard";
import styles from "./OrganizationComponent.module.css"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const OrganizationComponent = () => {
   const { t } = useTranslation();
   const navigate = useNavigate();
   const scrollToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: "smooth" // Opciono: pravi animirani scroll ka vrhu stranice
      });
   };
   return (
      <div className={styles.komponenta}>
         <h2 className={styles.tekst1}>{t("Upravljaj zadacima i dogadjajima efikasno i racionalno")}</h2>
         <h5 className={styles.tekst2}>{t("CoF pomaze klubovima i igracima da na jednostavan i pouzdan nacin  prate svoje aktivnosti i poboljsaju timske i individualne rezultate.")}
         </h5>

         <Row className={styles.kartice}>
         
            <Col xs={12} lg={4} className={styles.cardcontainer}>
               <OrganizationCard
                  header={t("Unapredite planiranje")}
                  text={t("Poboljsajte nacin planiranja i zakazivanja obaveza.")}
                  buttonText={t("Saznaj vise")}
                  onButtonClick={() => {
                     navigate('/whyuse'); // Navigacija na "/whyuse" rutu koristeći navigate
                     scrollToTop(); // Poziv funkcije za scroll na vrh stranice
                  }}
                  imageSrc="/images/Asset16.png"
               />
            </Col>
            <Col xs={12} lg={4} className={styles.cardcontainer}>
               <OrganizationCard
                  header={t("Preglednost.")}
                  text={t("Pracenje svih procesa unutar kluba u realnom vremenu.")}
                  buttonText={t("Saznaj vise")}
                  onButtonClick={() => {
                     navigate('/whyuse'); // Navigacija na "/whyuse" rutu koristeći navigate
                     scrollToTop(); // Poziv funkcije za scroll na vrh stranice
                  }}

                  imageSrc="/images/Asset15.png"
               />
            </Col>
            <Col xs={12} lg={4} className={styles.cardcontainer}>
               <OrganizationCard
                  header={t("Zavrsi obaveze")}
                  text={t("Na vreme pregledaj i pedantno zavrsi svoje obaveze u postavljenim rokovima.")}
                  buttonText={t("Saznaj vise")}
                  onButtonClick={() => {
                     navigate('/whyuse'); // Navigacija na "/whyuse" rutu koristeći navigate
                     scrollToTop(); // Poziv funkcije za scroll na vrh stranice
                  }}
                  imageSrc="/images/Asset14.png"
               />
            </Col>
            
         </Row>
      </div>

   )
}
export default OrganizationComponent;