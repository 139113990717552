import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from "./AlignTeams.module.css"
const Features = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.features} style={{  backgroundColor: "rgb(14,55,71)" }}>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>

                <h2 className={styles.nudi} style={{ fontWeight: '700', color: 'rgb(199, 198, 216)', lineHeight: '32px', paddingBottom: "40px" }}><span>{t("Cof nudi jednostavno")}</span><span style={{ color: "rgb(0,198,19)" }}>{t("rešenje za upravljanje")}</span><br className={styles.brr}></br><span style={{ color: "rgb(0,198,19)" }}>{t("fudbalskim klubom")}</span><span>{t("bez ograničenja")}</span></h2>
            </div>
            <Row style={{ justifyContent: 'center', paddingLeft: "100px", paddingRight: "100px" }}>
                <Col xs={12} sm={12} md={3} style={{ textAlign: 'center' }}>
                    <img src="./images/asset1.png" alt="Slika 1" style={{ marginBottom: '0.5rem' }} />
                    <p style={{ textAlign: 'center', color: 'white', paddingTop: '20px' }}>{t("NEOGRANIČEN BROJ ")}<br></br><span style={{ color: 'rgb(0,198,19)', borderBottom: '2px solid rgb(0,198,19)', paddingBottom: '5px' }}>{t("PLANOVA")}</span></p>
                </Col>
                <Col xs={12} sm={12} md={3} style={{ textAlign: 'center' }}>
                    <img src="./images/asset2.png" alt="Slika 2" style={{ marginBottom: '1rem' }} />
                    <p style={{ textAlign: 'center', color: 'white', paddingTop: '20px' }}>{t("NEOGRANIČEN BROJ ")}<br></br><span style={{ color: 'rgb(0,198,19)', borderBottom: '2px solid rgb(0,198,19)', paddingBottom: '5px' }}>{t("ZADATAKA")}</span></p>
                </Col>
                <Col xs={12} sm={12} md={3} style={{ textAlign: 'center' }}>
                    <img src="./images/NeogranicenBrojkorisnika.png" alt="Slika 3" style={{ marginBottom: '0.5rem', width: "140px" }} />
                    <p style={{ textAlign: 'center', color: 'white', paddingTop: '20px' }}>{t("NEOGRANIČEN BROJ ")}<br></br><span style={{ color: 'rgb(0,198,19)', borderBottom: '2px solid rgb(0,198,19)', paddingBottom: '5px' }}>{t("KORISNIKA")}</span></p>
                </Col>
            </Row>

        </div>
    );
}

export default Features;

