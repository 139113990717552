// Card.js

import React from 'react';
import styles from './Card.module.css';

const Card = ({ title, description, backgroundColor }) => {
  const cardStyle = {
    backgroundColor: backgroundColor || 'rgb(14,55,71)',
    fontSize: '17px'
  };

  return (
    <div className={styles.card} style={cardStyle}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
}

export default Card;
