import React from "react";
import { Col,Row,Button} from "react-bootstrap";
import  styles from "./COFuse.module.css"
import CustomButton from "./CustomButton";
import { useTranslation } from "react-i18next";
const COFuse = () =>{
  const { t } = useTranslation();

  const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;

// Dodajte putanje za prijavu i registraciju na base URL
const signUpUrl = `${appBaseUrl}/signup`;
const signInUrl = `${appBaseUrl}/signin`;

// Handler funkcija za klik na dugme "Sign In"
const handleSignUpClick = () => {
  // Preusmeravanje na stranicu za registraciju
  window.location.href = signUpUrl;
};

const handleSignInClick = () => {
  // Preusmeravanje na stranicu za prijavljivanje
  window.location.href = signInUrl;
};
    return(
        <div className={styles.komponenta}>
          <Row className={styles.red} >
          
            <Col  xs={12} lg={6} className={styles.slikacol}>
              <div>
                <img alt="slika" src="/images/laptop.png" className={styles.slika}></img>
                
              </div>
            </Col>
            <Col xs={12} lg={6} className={styles.tekstovi}>
            <h4 style={{fontWeight:"700"}}>
                {t("Da li je COF pravi izbor za vas?")}
            </h4>
            <div className={styles.horizontalnadiv}>
            <hr className={styles.horizontalna}></hr>
            </div>
            <div className={styles.questions}>
            <h6 className={styles.questionparagraph}> {t("Tvoj klub raste i napreduje, imas sve vise osoblja ukljucenih u organizaciju i")} <span style={{fontWeight:"bold"}}>{t("sve ti je teze da ispratis dnevno funkcionisanje i zaduzenja?")}</span></h6>
            <h6 className={styles.questionparagraph}>{t("Osecas frustraciju zbog cestog nedostatka komunikacije oko trivijalnih informacija?")}</h6>
            <h6 className={styles.questionparagraph}> {t("Zelis vecu preglednost i transparentnost obaveza, kao i")}<span style={{fontWeight:"bold"}}>{t(" mogucnost da blagovremeno odreagujes na nezeljene situacije?")}</span></h6>
            

            </div>
            <div className={styles.divdugme}>
            <CustomButton  label={t("Isprobajte besplatno")} className={styles.novodugme} onClick={handleSignUpClick} /></div>
            <Row>
              <Col>
              <p className={styles.secondheader} >{t("Isprobaj sve funkcionalnosti aplikacije 60 dana besplatno.")}</p>
              </Col>
            </Row>
            </Col>
          </Row>

        </div>
    )
}

export default COFuse;