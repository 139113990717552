// ImageWithBackground.js

import React from 'react';
import styles from './ImageWithBackground.module.css'; // Uvezivanje CSS modula

const ImageWithBackground = () => {
    const imageUrl = "/images/laptop.png"; // Putanja do slike
    

    return (
        <div className={styles.containerStyle}>
            <div className={styles.backgroundTop}></div>
            <div className={styles.backgroundBottom}></div>
            <img src={imageUrl} alt="Background" className={styles.image} />
        </div>
    );
};

export default ImageWithBackground;
