import React from "react";

const CustomerReviewCard = ({ image, text, author, position, backgroundColor }) => {
  const cardStyles = {
    ...styles.card,
    backgroundColor: backgroundColor || styles.card.backgroundColor // Use prop value or default to styles.card.backgroundColor
  };

  return (
    <div style={cardStyles}>
      {image && <img src={image} alt="Review" style={styles.image} />}
      <div style={styles.text}>{text}</div>
      <div style={styles.author}>{author}</div>
      <div style={styles.position}>{position}</div>
    </div>
  );
};

const styles = {
  card: {
    border: '',
    borderRadius: '8px',
    textAlign: 'left',
    display: 'flex',
    flexWrap: "wrap",
    flexDirection: 'column',
    backgroundColor:"#1d4e5b",
    color:"whitesmoke",
    paddingTop: "23px",
    paddingBottom: "23px",
  },
  image: {
    width: '90px',
    marginBottom: '1.5rem',
    textAlign: 'left',
    padding: '0 20px'
  },
  text: {
    marginBottom: '2rem',
    paddingBottom:"2rem",
    paddingLeft: "5px",
    textAlign: 'left',
    height: '150px',
    fontStyle:"italic",
    fontSize:"1rem"
  },
  author: {
    verticalAlign: 'bottom', // Align the text at the bottom
    fontWeight:"500",
    paddingLeft: "5px",
  },
  position:{
    color:"#778389",
    paddingLeft: "5px",
  }
};

export default CustomerReviewCard;
