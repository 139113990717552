import React from "react";
import { useNavigate } from 'react-router-dom';
import { Col, Row, Button } from "react-bootstrap";
import styles from "./HeroSection.module.css";
import CustomButton from "./CustomButton";
import "../index.css";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleDemoClick = () => {
    // Navigacija na novu stranicu kada se klikne na "Get a Demo"
    navigate('/demo');
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Dodajte 'smooth' za glatko pomeranje
    });
  };

  const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;

// Dodajte putanje za prijavu i registraciju na base URL
const signUpUrl = `${appBaseUrl}/signup`;
const signInUrl = `${appBaseUrl}/signin`;

// Handler funkcija za klik na dugme "Sign In"
const handleSignUpClick = () => {
  // Preusmeravanje na stranicu za registraciju
  window.location.href = signUpUrl;
};

const handleSignInClick = () => {
  // Preusmeravanje na stranicu za prijavljivanje
  window.location.href = signInUrl;
};

  return (
    <div className={styles.res100}>
      <Row className={styles.rowcss}  >
        <Col xs={12} lg={6} className={styles.buttoncontainer}>
          <h2 style={{ fontWeight: "900" }} className={styles.mainheader}>
            {t("softverza")} <span style={{ color: "#00c613" }}>{t("automatizaciju i optimizaciju procesa")}</span> {t("fudbalskih klubova")}.
          </h2>
          <hr className={styles.horizontalna}></hr>
          <h6 className={styles.secondheader}>{t("Kreiraj mrezu odgovornosti i")} <span style={{ fontWeight: "700" }}>{t("jednostavno resenje za uravljanje resursima i dogadjajima.")} </span>
            {t("Upravljaj zadacima i dogadjajima unutar kluba bez stotina nepotrebnih poruka na dnevnom nivou.")}</h6>
          <Row className={styles.buttonstyle} ><Col lg={6} xs={6} className={styles.buttoncontainer}><CustomButton label={t("Isprobajte besplatno")} className="custom-button-primary" onClick={handleSignUpClick} /></Col>
            <Col lg={6} xs={6} className={styles.buttoncontainer}><CustomButton label={t("Zakazite demo verziju")} className="custom-button-primary " onClick={handleDemoClick} /></Col></Row>
          <p className={styles.secondheader} >{t("Isprobaj sve funkcionalnosti aplikacije 60 dana besplatno.")}</p>
        </Col>
        <Col className="text-center" xs={12} lg={6}>
          <div>
            <img src="/images/HomePageAnimation.gif" alt="nema slike" className={styles.image} ></img>
            <p className={styles.secondheader} style={{ paddingBottom: "0" }}>{t("CoF mi je pomogao da jasno iskomuniciram obaveze unutar kluba i dobijem vise vremena za kvalitetnije i sadrzajnije treninge.")}
            </p>
            <p className={styles.imagetext}>Bojan Miletić, {t("pomocnik menadzera")}</p>
            <p className={styles.imagetextt}>{t("FK")} IMT Beograd</p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default HeroSection;