import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'; // Uvoz Provider komponente iz react-redux paketa
import store from './store'; // Uvoz Redux store-a iz 'store.js' fajla
import '@fortawesome/fontawesome-free/css/all.css';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en.json";
import srbTranslation from "./locales/srb.json";


i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    srb: { translation: srbTranslation },
  },
  lng: "srb",
  fallbackLng: "srb",
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
