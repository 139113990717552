import axios from "axios";

import {
    FETCH_POSTS_REQUEST,
    FETCH_POSTS_SUCCESS,
    FETCH_POSTS_FAILURE,
  } from "../constants/blogConstants";

  export const fetchPosts = () => {
    return dispatch => {
      dispatch({ type: FETCH_POSTS_REQUEST });
  
      fetch('https://www.nutricentar.com/wp-json/wp/v2/posts')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          dispatch({
            type: FETCH_POSTS_SUCCESS,
            payload: data
          });
        })
        .catch(error => {
          dispatch({
            type: FETCH_POSTS_FAILURE,
            payload: error.message
          });
        });
    };
  };
  