import React from "react";
import HeroSection from "../Components/HeroSection";
import OrganizationComponent from "../Components/OrganizationComponent";
import COFuse from "../Components/COFuse";
import UseCases from "../Components/UseCases";
import CustomerReview from "../Components/CustomerReview";
import CustomerSupport from "../Components/CustomerSupport";
import Cofcta from "../Components/COFCTA";
const HomeScreen = () =>{

    return(
        <div>
        <div style={{backgroundColor:"rgb(14,55,71)"}}><HeroSection /></div>
        <div style={{backgroundColor:'#1d4e5b' }}><OrganizationComponent /></div>
        <div style={{backgroundColor:"#0e3747"}}><COFuse /></div>
        <div style={{backgroundColor:'#1d4e5b' }}><UseCases /></div>
        <div style={{backgroundColor:"#0e3747"}}><CustomerReview isPrimaryColor={true}/></div>
        <div style={{backgroundColor: '#1d4e5b'}}><CustomerSupport /></div>
        <div style={{backgroundColor:"rgb(14,55,71)"}}><Cofcta /></div>
        </div>
    )
}

export default HomeScreen;