import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import CustomButton from "../CustomButton";
import { useTranslation } from "react-i18next";

const BenefitComponent = () => {
  const { t } = useTranslation();
  const appBaseUrl = process.env.REACT_APP_APP_BASE_URL;
  const signUpUrl = `${appBaseUrl}/signup`;
  
  const handleSignUpClick = () => {
    // Preusmeravanje na stranicu za registraciju
    window.location.href = signUpUrl;
  };

  return (
    <div style={{ backgroundColor: '#1d4e5b', paddingTop: '10px' }}>
    <Container>
      <Row className="px-3" style={{ color: "rgb(199, 198, 216)" }}>
        
        <Col xs={12} sm={4} className="px-3" style={{borderLeft: "2px solid green", paddingLeft: "10px", }}>
          <h4 style={{color:"rgb(0, 198, 19)",fontSize:"18px", fontWeight:"600", lineHeight:"28px"}}>{t("Pregled obaveza u realnom vremenu")}</h4>
          <p style={{fontSize:"16px", fontWeight:"300", lineHeight:"24px", paddingTop:"20px"}}>{t("Pregled obaveza u realnom vremenu-tekst")}</p>
        </Col>
        <Col xs={12} sm={4} className="px-3" style={{borderLeft: "2px solid green", paddingLeft: "10px" }}>
          <h4 style={{color:"rgb(0, 198, 19)",fontSize:"18px", fontWeight:"600", lineHeight:"28px"}}>{t("Pratite dnevni status igrača")}</h4>
          <p style={{fontSize:"16px", fontWeight:"300", lineHeight:"24px", paddingTop:"20px"}}>{t("Pratite dnevni status igrača-tekst")}</p>
        </Col>
        <Col xs={12} sm={4} className="px-3" style={{borderLeft: "2px solid green", paddingLeft: "10px" }}>
          <h4 style={{color:"rgb(0, 198, 19)",fontSize:"18px", fontWeight:"600", lineHeight:"28px"}}>{t("Lako dostupno")}</h4>
          <p style={{fontSize:"16px", fontWeight:"300", lineHeight:"24px", paddingTop:"20px"}}>{t("Lako dostupno-tekst")}</p>
        </Col>
        
      </Row>
      </Container>
      <Row
        className="justify-content-center"
        style={{
          paddingBottom: "100px",
          paddingTop: "60px",
        }}
      >
        <Col xs="auto">
          <CustomButton label={t("Prijavite_se_besplatno")} className="custom-button-primary" onClick={handleSignUpClick} />
        </Col>
      </Row>
    </div>
  );
};

export default BenefitComponent;
